* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root,
body,
html {
  /* height: 100%; */
  margin: 0;
  font-size: inherit;
  font-family: 'Source Sans Pro', sans-serif;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .flip-y {
    transform: scale(1, -1);
  }
}

@layer base {
  img {
    max-width: revert;
  }
}
